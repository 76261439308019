import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useTrackEventMutation } from "@components/layout/operations.generated";
import { UserContextState } from "@contexts/UserContext";
import { useRouter } from "next/router";
import { initializeTracking } from "@src/utils/track";

export interface TrackingContextState {
  trackEvent: (label: string, payload: Record<string, unknown>) => void;
}

const TrackingContext = React.createContext<TrackingContextState>({
  trackEvent: () => {},
});

export default TrackingContext;

type Props = {
  children: React.ReactNode;
  userContext?: UserContextState;
};

export const TrackingContextProvider: FC<Props> = ({
  children,
  userContext,
}) => {
  const router = useRouter();
  const trackEvent = useTrackEventMutationWrapper(userContext);
  useEffect(() => {
    initializeTracking(trackEvent);
  }, [trackEvent]);
  useEffect(() => {
    trackEvent("viewed_page", {
      pathname: router.pathname,
      query: router.query,
    });
  }, [router, trackEvent]);
  const trackingContextValue = useMemo(() => ({ trackEvent }), [trackEvent]);
  return (
    <TrackingContext.Provider value={trackingContextValue}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTrackEventMutationWrapper = (
  userContext?: UserContextState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ((label: string, payload: any) => void) => {
  const [trackEventMutation] = useTrackEventMutation();
  return useCallback(
    (label, payload) => {
      if (!userContext?.user) {
        return;
      }
      trackEventMutation({
        variables: {
          label,
          payload,
        },
      });
    },
    [trackEventMutation, userContext?.user],
  );
};
