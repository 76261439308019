import { toast } from "sonner";
import { ToastErrorInfo, AddToast } from "@src/contexts/ToastContext";
import { track } from "./track";

export const addToast = (toastConfig: AddToast): string | number => {
  const { message, success = true, lifetimeMS } = toastConfig;
  if (success) {
    return toast.success(message, {
      duration: lifetimeMS,
    });
  } else {
    return toast.info(message, {
      duration: lifetimeMS,
    });
  }
};

export const addErrorToast = (errorInfo: ToastErrorInfo): string | number => {
  const message =
    typeof errorInfo.message === "string"
      ? errorInfo.message
      : "An error occurred";
  track("error_toast_visible", {
    message,
    ...errorInfo,
    error: errorInfo,
    pathname: window.location.pathname,
  });

  return toast.error(
    errorInfo.message || "Something went wrong! Please try again.",
  );
};
