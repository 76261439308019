let trackEventFn:
  | ((label: string, payload?: Record<string, unknown>) => void)
  | null = null;

export const initializeTracking = (
  fn: (label: string, payload?: Record<string, unknown>) => void,
): void => {
  trackEventFn = fn;
};

export const track = (
  label: string,
  payload: Record<string, unknown> = {},
): void => {
  if (!trackEventFn) {
    console.warn("Tracking not initialized");
    return;
  }
  trackEventFn(label, payload);
};
